<template>
  <div class="managerPoints content-index">
    <div class="header">
      <div class="header-top">
        <span class="title">我的店长分</span>
        <el-date-picker
            v-model="time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-button type="primary" @click="confirm">确定</el-button>
        <el-button type="primary" @click="refresh">刷新</el-button>
      </div>
    </div>
    <div class="content">
      <el-row :gutter="5">
        <el-col :span="6">
          <el-card shadow="never">
            <el-skeleton style="text-align: center;" :loading="levelLoading" animated>
              <template slot="template">
                <el-skeleton-item variant="rect" style="width: 220px; height: 180px;margin-top: 30px"/>
                <div style="padding: 14px;">
                  <el-skeleton-item variant="p" style="width: 50%;"/>
                  <el-skeleton-item variant="p" style="width: 90%;margin-top: 10px"/>
                  <el-skeleton-item variant="p" style="width: 50%;margin-top: 10px"/>
                  <el-skeleton-item variant="p" style="width: 90%;margin-top: 10px"/>
                </div>
              </template>
              <template>
                <div style="width: 100%;height: 200px">
                  <vab-chart
                      theme="vab-echarts-theme"
                      :init-options="initOptions"
                      :options="instrumentData"
                      :style="{width:'100%',height:'400px'}"
                      class="instrument">
                  </vab-chart>
                </div>
                <div class="level">
                  <div class="currLevel">
                    <div class="currLevel_title">
                      <span>当前提成收益</span>
                    </div>
                    <div class="currLevel_desc">{{ storeUserScoreChangeVo.currLevel }}</div>
                  </div>
                  <div class="lastLevel">
                    <div class="lastLevel_title">
                      <span>下一级别提成收益</span>
                    </div>
                    <div class="lastLevel_desc">{{ storeUserScoreChangeVo.lastLevel }}</div>
                  </div>
                </div>
              </template>
            </el-skeleton>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="never">
            <el-skeleton :loading="levelLoading" animated>
              <template slot="template">
                <div style="padding: 10px 0 0 20px;">
                  <el-skeleton-item variant="p" style="width: 60%;height: 35px"/>
                  <div>
                    <el-skeleton-item variant="p" style="width: 90%;"/>
                  </div>
                </div>
                <div style="padding: 14px;text-align: center">
                  <el-skeleton-item variant="p" style="width: 90%;height: 35px"/>
                  <el-skeleton-item variant="p" style="width: 90%;margin-top: 10px"/>
                </div>
                <div>
                  <el-row :gutter="20" style="margin: 15px">
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" style="margin: 15px">
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" style="margin: 15px">
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                  </el-row>
                </div>
              </template>
              <template>
                <div class="my-card">
                  <div class="card-title">昨日分数变动</div>
                  <span class="title-desc">分数变动=新增有效分数-过期分数</span>
                  <span class="straight"></span>
                </div>
                <div class="yesterday">
                  <div class="yesterday-item">
                    <span class="effective_score">{{ yesterdayScore.newValidScore }}</span><br/>
                    <span class="score_title">新增有效分数</span>
                  </div>
                  <span class="minusa">-</span>
                  <div class="yesterday-item">
                    <span class="expired_score">{{ yesterdayScore.expiryDateScore }}</span><br/>
                    <span class="score_title">当日过期分数</span>
                  </div>
                  <span class="minus">=</span>
                  <div class="yesterday-item">
                    <span class="yesterday_change_score">{{ yesterdayScore.changesInYesterday }}</span><br/>
                    <span class="score_title">昨日变动</span>
                  </div>
                </div>
                <div class="yesterday-footer">
                  <div class="footer-item">
                    <div class="newEffective">
                  <span class="score_num" :style="{
                      color:
                        yesterdayScore.validServiceScore > 0 ? '#FF3A3A' : ( yesterdayScore.validServiceScore < 0? '#2CE371' : '#808080'),
                    }"><span class="score_num" v-if="this.yesterdayScore.validServiceScore >= 0">+</span>{{
                      yesterdayScore.validServiceScore
                    }}</span>
                      <span class="score_title">服务分</span>
                    </div>
                    <div class="newEffective">
                  <span class="score_num" :style="{
                      color:
                        yesterdayScore.validBehaviorScore > 0 ? '#FF3A3A' : (yesterdayScore.validContributionScore < 0? 'green' : ('gray'))}">
                    <span class="score_num" v-if="yesterdayScore.validBehaviorScore >= 0">+</span
                    >{{ yesterdayScore.validBehaviorScore }}</span
                  >
                      <span class="score_title">行为分</span>
                    </div>
                    <div class="newEffective">
                  <span
                      class="score_num"
                      :style="{
                      color:
                        yesterdayScore.validContributionScore > 0
                          ? 'red'
                          : (yesterdayScore.validContributionScore < 0? 'green' : ('gray')),
                    }"
                  >
                    <span class="score_num" v-if="yesterdayScore.validContributionScore >= 0">+</span
                    >{{ yesterdayScore.validContributionScore }}</span
                  >
                      <span class="score_title">贡献分</span>
                    </div>
                  </div>
                  <div class="long"></div>
                  <div class="footer-item">
                    <div class="newEffective">
                  <span
                      class="score_num"
                      :style="{
                      color:
                        yesterdayScore.expireServiceScore > 0 ? 'red' : (yesterdayScore.expireServiceScore < 0? 'green' : 'gray'),
                    }"
                  ><span class="score_num" v-if="yesterdayScore.expireServiceScore >= 0">+</span
                  >{{ yesterdayScore.expireServiceScore }}</span
                  >
                      <span class="score_title">服务分</span>
                    </div>
                    <div class="newEffective">
                  <span
                      class="score_num"
                      :style="{
                      color:
                        yesterdayScore.expireBehaviorScore > 0
                          ? 'red'
                          : (yesterdayScore.expireBehaviorScore < 0? 'green' : 'gray'),
                    }"
                  >
                    <span class="score_num" v-if="yesterdayScore.expireBehaviorScore >= 0">+</span
                    >{{ yesterdayScore.expireBehaviorScore }}</span
                  >
                      <span class="score_title">行为分</span>
                    </div>
                    <div class="newEffective">
                  <span
                      class="score_num"
                      :style="{
                      color:
                        yesterdayScore.expireContributionScore > 0
                          ? 'red'
                          : (yesterdayScore.expireContributionScore < 0? 'green' : 'gray'),
                    }"
                  >
                    <span class="score_num" v-if="yesterdayScore.expireContributionScore >= 0">+</span
                    >{{ yesterdayScore.expireContributionScore }}</span
                  >
                      <span class="score_title">贡献分</span>
                    </div>
                  </div>
                  <div class="long"></div>
                  <div class="footer-item">
                    <div class="newEffective">
                  <span
                      class="score_num"
                      :style="{
                      color:
                        yesterdayScore.servicePoints > 0
                          ? 'red'
                          : (yesterdayScore.servicePoints < 0? 'green' : 'gray'),
                    }"
                  >
                    <span class="score_num" v-if="yesterdayScore.servicePoints >= 0">+</span
                    >{{ yesterdayScore.servicePoints }}</span
                  >
                      <span class="score_title">服务分</span>
                    </div>
                    <div class="newEffective">
                  <span
                      class="score_num"
                      :style="{
                      color:
                        yesterdayScore.actionPoints > 0
                          ? 'red'
                          : (yesterdayScore.actionPoints < 0? 'green' : 'gray'),
                    }"
                  >
                    <span class="score_num" v-if="yesterdayScore.actionPoints >= 0">+</span
                    >{{ yesterdayScore.actionPoints }}</span
                  >
                      <span class="score_title">行为分</span>
                    </div>
                    <div class="newEffective">
                  <span
                      class="score_num"
                      :style="{
                      color:
                        yesterdayScore.validContributionScore > 0
                          ? 'red'
                          : (yesterdayScore.validContributionScore < 0? 'green' : 'gray'),
                    }"
                  >
                    <span class="score_num" v-if="yesterdayScore.contributionPoints >= 0">+</span
                    >{{ yesterdayScore.contributionPoints }}</span
                  >
                      <span class="score_title">贡献分</span>
                    </div>
                  </div>
                </div>
              </template>
            </el-skeleton>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="never">
            <el-skeleton :loading="levelLoading" animated>
              <template slot="template">
                <div style="padding: 10px 0 0 20px;">
                  <el-skeleton-item variant="p" style="width: 60%;height: 35px"/>
                  <div>
                    <el-skeleton-item variant="p" style="width: 90%;"/>
                  </div>
                </div>
                <div style="padding: 14px;text-align: center;margin-top: 20px">
                  <el-skeleton-item variant="p" style="width: 70%;height: 35px"/>
                  <el-skeleton-item variant="p" style="width: 90%;height: 35px;margin-top: 10px"/>
                </div>
                <div>
                  <el-row :gutter="20" style="margin: 30px 5px 0 5px">
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                  </el-row>
                </div>
                <div style="text-align: center;margin-top: 12px">
                  <el-skeleton-item variant="p" style="width: 90%;height: 30px;margin-top: 10px"/>
                </div>
              </template>
              <template>
                <div class="my-card">
                  <div class="card-title">服务分</div>
                  <span class="title-desc">统计前500笔订单分数加合</span>
                  <span class="straight"></span>
                  <div class="num" @click="angle('ServicePoints')">
                    <strong>{{ serviceScore }}分</strong>
                    <span class="angle">&nbsp;></span>
                  </div>
                  <div class="statement">
                    <span class="score_desc">{{ serviceWriting.copyContent }}</span>
                  </div>
                  <div class="f-item">
                    <div class="num-item">
                  <span
                      class="num_item_score"
                      :style="{
                      color: defaultPraiseScore > 0 ? '#FF3A3A' : (defaultPraiseScore < 0? '#2CE371' : '#808080'),
                    }"
                  >
                    <span v-if="defaultPraiseScore > 0">+</span
                    >{{ defaultPraiseScore }}</span
                  >
                      <br/>
                      <div class="num_item_score_title">默认好评</div>
                    </div>
                    <div class="montant"></div>
                    <div class="num-item">
                  <span
                      class="num_item_score"
                      :style="{
                      color: initiativePraiseScore > 0 ? '#FF3A3A' : (initiativePraiseScore < 0? '#2CE371' : '#808080'),
                    }"
                  >
                    <span v-if="initiativePraiseScore > 0">+</span
                    >{{ initiativePraiseScore }}</span
                  >
                      <br/>
                      <div class="num_item_score_title">主动评价</div>
                    </div>
                    <div class="montant"></div>
                    <div class="num-item item3">
                  <span
                      class="num_item_score"
                      :style="{
                      color: complaintScore > 0 ? '#FF3A3A' : (complaintScore < 0 ? '#2CE371' : '#808080'),
                    }"
                  >
                    <span v-show="complaintScore > 0">+</span
                    >{{ complaintScore }}</span
                  >
                      <br/>
                      <div class="num_item_score_title">渠道投诉</div>
                    </div>
                  </div>
                  <div class="card-footer" @click="getByType(4)">点击查看服务分规则</div>
                </div>
              </template>
            </el-skeleton>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="never">
            <el-skeleton :loading="levelLoading" animated>
              <template slot="template">
                <div style="padding: 10px 0 0 20px;">
                  <el-skeleton-item variant="p" style="width: 60%;height: 35px"/>
                  <div>
                    <el-skeleton-item variant="p" style="width: 90%;"/>
                  </div>
                </div>
                <div style="padding: 14px;text-align: center;margin-top: 20px">
                  <el-skeleton-item variant="p" style="width: 70%;height: 35px"/>
                  <el-skeleton-item variant="p" style="width: 90%;height: 35px;margin-top: 10px"/>
                </div>
                <div>
                  <el-row :gutter="20" style="margin: 30px 5px 0 5px">
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                  </el-row>
                </div>
                <div style="text-align: center;margin-top: 12px">
                  <el-skeleton-item variant="p" style="width: 90%;height: 30px;margin-top: 10px"/>
                </div>
              </template>
              <template>
                <div class="my-card">
                  <div class="card-title">行为分</div>
                  <span class="title-desc">统计对应时间内订单分数加合</span>
                  <span class="straight"></span>
                  <div class="num" @click="angle('ActionPoints')">
                    <strong>{{ behaviorScore }}分</strong>
                    <span class="angle">&nbsp;></span>
                  </div>
                  <div class="statement">
                <span class="score_desc">{{
                    behaviorWriting.copyContent
                  }}</span>
                  </div>
                  <div class="f-item">
                    <div class="num-item">
                  <span
                      :style="{ color: standardScore > 0 ? 'red' : (standardScore < 0? 'green' : 'gray') }"
                  >
                    <span v-if="standardScore > 0">+</span
                    >{{ standardScore }}</span
                  >
                      <br/>
                      <div class="num_item_score_title">行为规范</div>
                    </div>
                    <div class="montant"></div>
                    <div class="num-item">
                  <span class="num_item_score"
                        :style="{ color: executeScore > 0 ? 'red' : (executeScore < 0? 'green' : 'gray') }">
                    <span v-if="executeScore > 0">+</span
                    >{{ executeScore }}</span
                  >
                      <br/>
                      <div class="num_item_score_title">执行力</div>
                    </div>
                    <div class="montant"></div>
                    <div class="num-item item3">
                  <span class="num_item_score"
                        :style="{ color: learnScore > 0 ? 'red' : (learnScore < 0? 'green' : 'gray') }">
                    <span v-if="learnScore > 0">+</span>{{ learnScore }}</span
                  >
                      <br/>
                      <div class="num_item_score_title">学习力</div>
                    </div>
                  </div>
                  <div class="card-footer" @click="getByType(5)">点击查看行为分规则</div>
                </div>
              </template>
            </el-skeleton>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="never">
            <el-skeleton :loading="levelLoading" animated>
              <template slot="template">
                <div style="padding: 10px 0 0 20px;">
                  <el-skeleton-item variant="p" style="width: 60%;height: 35px"/>
                  <div>
                    <el-skeleton-item variant="p" style="width: 90%;"/>
                  </div>
                </div>
                <div style="padding: 14px;text-align: center;margin-top: 20px">
                  <el-skeleton-item variant="p" style="width: 70%;height: 35px"/>
                  <el-skeleton-item variant="p" style="width: 90%;height: 35px;margin-top: 10px"/>
                </div>
                <div>
                  <el-row :gutter="20" style="margin: 30px 5px 0 5px">
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                    <el-col :span="8">
                      <el-skeleton-item variant="rect" style="height: 40px;"/>
                    </el-col>
                  </el-row>
                </div>
                <div style="text-align: center;margin-top: 12px">
                  <el-skeleton-item variant="p" style="width: 90%;height: 30px;margin-top: 10px"/>
                </div>
              </template>
              <template>
                <div class="my-card">
                  <div class="card-title">贡献分</div>
                  <span class="title-desc">统计对应时间内订单分数加合</span>
                  <span class="straight"></span>
                  <div class="num" @click="angle('ContributionPoints')">
                    <strong>{{ contributionScore }}分</strong>
                    <span class="angle">&nbsp;></span>
                  </div>
                  <div class="statement">
                <span class="score_desc">
                  {{ contributionWriting.copyContent }}</span
                >
                  </div>
                  <div class="f-item">
                    <div class="num-item">
                  <span
                      class="num_item_score"
                      :style="{
                      color: seniorityAwardScore > 0 ? 'red' : (seniorityAwardScore < 0? 'green' : 'gray'),
                    }"
                  >
                    <span v-if="seniorityAwardScore > 0">+</span>{{ seniorityAwardScore }}</span
                  >
                      <br/>
                      <div class="num_item_score_title">工龄奖励</div>
                    </div>
                    <div class="montant"></div>
                    <div class="num-item">
                  <span
                      class="num_item_score"
                      :style="{
                      color: otherContributionScore > 0 ? 'red' : (otherContributionScore < 0 ? 'green' : 'gray'),
                    }"
                  >
                    <span v-if="otherContributionScore > 0">+</span
                    >{{ otherContributionScore }}</span
                  >
                      <br/>
                      <div class="num_item_score_title">其它贡献</div>
                    </div>
                  </div>
                  <div class="card-footer" @click="getByType(6)">点击查看贡献分规则</div>
                </div>
              </template>
            </el-skeleton>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="footer">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span style="font-size: 16px;font-weight: bold">分数变动明细：</span>
        </div>
        <div>
          <el-skeleton :loading="levelLoading" animated>
            <template slot="template">
              <div style="padding: 10px 0 0 20px;">
                <el-skeleton-item variant="p" style="width: 95%;height: 30px"/>
              </div>
              <div style="padding: 10px 0 0 20px;">
                <el-skeleton-item variant="p" style="width: 80%;"/>
                <el-skeleton-item variant="p" style="width: 90%;"/>
              </div>
            </template>
            <template>
              <el-table
                  :data="tableData"
                  :header-cell-style="{background: '#fff',color: '#000'}"
                  stripe
                  style="width: 100%"
              >
                <el-table-column label="序号" align="center">
                  <template slot-scope="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="date" label="日期" align="center"/>
                <el-table-column prop="scoreType" label="类别" align="center"/>
                <el-table-column prop="scoreStatus" label="状态" align="center"/>
                <el-table-column prop="channelType" label="渠道" align="center"/>
                <el-table-column prop="nameType" label="名称" align="center"/>
                <el-table-column prop="num" label="数量" align="center"/>
                <el-table-column prop="illustrate" label="说明" show-overflow-tooltip align="center"/>
                <el-table-column prop="validity" label="有效期" align="center"/>
                <el-table-column prop="score" label="分数">
                  <template slot-scope="scope">
                    <div :style="{ color: scope.row.score > 0 ? 'red' : (scope.row.score < 0 ? 'green' : 'gary') }">
                      <span v-if="scope.row.score > 0">+</span>{{ scope.row.score }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-skeleton>
        </div>
      </el-card>
    </div>
    <el-dialog
        :title="copyTitle"
        :visible.sync="dialogVisible"
        width="60%">
      <span class="copy-item">{{ copyContent }}</span>
    </el-dialog>
  </div>
</template>

<script>
import {
  storeUserLookAScore,
  getStoreScoreRecordByDate,
  getByType
} from "@/api/storeManagerPoints/index.js";
import VabChart from '@/components/VabChart'

export default {
  components: {
    VabChart,
  },
  data() {
    return {
      dialogVisible: false,
      input: "",
      time: this.$moment().subtract(1, 'days').format('YYYY-MM-DD'),
      title: "",
      tableData: [],
      pickerOptions: {
        onPick: ({maxDate, minDate}) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = "";
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== "") {
            const one = 365 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return (
                time.getTime() < minTime ||
                time.getTime() > maxTime ||
                time.getTime() > Date.now() - 8.64e6
            );
          }
        },
      },
      //共计
      sum: {
        record: "", //记录
        total: "", //总得分
        baseScore: "", //基础分
        score: "", //得分
      },
      storeUserScoreChangeVo: [], //仪表数据
      yesterdayScore: [], //昨日分数变动
      score: 0, //仪表分
      managerLogoName: "", // 等级店长
      serviceWriting: [], //服务分文案
      behaviorWriting: [], //行为分文案
      contributionWriting: [], //贡献分文案
      behaviorScore: 0, //行为分
      contributionScore: 0, //贡献分
      serviceScore: 0, //服务分

      defaultPraiseScore: "", //默认好评
      initiativePraiseScore: "", //主动评价
      complaintScore: "", //渠道投诉
      standardScore: "", //行为规范
      executeScore: "", //执行力
      learnScore: "", //学习力
      seniorityAwardScore: "", //工龄奖励
      otherContributionScore: "", //其他贡献
      copyContent: '', //规则
      copyTitle: '',
      // 图表数据
      initOptions: {
        renderer: 'svg',
      },
      instrumentData: {},
      instrumentCharts: null,
      // 等级loading
      levelLoading: false
    }
  },
  mounted() {
  },
  created() {
    this.storeUserLookAScore();
    this.getStoreScoreRecordByDate();
  },
  methods: {
    //店长分页面数据
    storeUserLookAScore() {
      this.levelLoading = true
      storeUserLookAScore().then((res) => {
        if (res.data.code == 0) {
          this.levelLoading = false
          let data = res.data.data;
          this.storeUserScoreChangeVo = data.storeUserScoreChangeVo; //仪表数据
          this.yesterdayScore = data.yesterdayScore; //昨日数据
          this.score = data.score; //仪表分
          this.managerLogoName = data.managerLogoName; // 等级店长
          this.serviceWriting = data.serviceWriting; //服务分文案
          this.behaviorWriting = data.behaviorWriting; //行为分文案
          this.contributionWriting = data.contributionWriting; //贡献分文案
          this.behaviorScore = data.behaviorScore; //行为分
          this.contributionScore = data.contributionScore; //贡献分
          this.serviceScore = data.serviceScore; //服务分
          this.defaultPraiseScore = data.defaultPraiseScore; //默认好评
          this.initiativePraiseScore = data.initiativePraiseScore; //主动评价
          this.complaintScore = data.complaintScore; //渠道投诉
          this.standardScore = data.standardScore; //行为规范
          this.executeScore = data.executeScore; //执行力
          this.learnScore = data.learnScore; //学习力
          this.seniorityAwardScore = data.seniorityAwardScore; //工龄奖励
          this.otherContributionScore = data.otherContributionScore; //其他贡献
          this.instrumentChart();
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    confirm() {
      this.getStoreScoreRecordByDate();
    },
    refresh() {
      this.storeUserLookAScore();
    },
    //店长分表格数据
    getStoreScoreRecordByDate() {
      let params = {
        date: this.time
      }
      getStoreScoreRecordByDate(params).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    //点击弹出弹出层
    angle(path) {
      this.$router.push({name: path});
    },
    inquire(val) {
      console.log(val, "接受到值了吗");
      this.tableData = [];
    },
    getByType(id) {
      this.dialogVisible = true;
      if (id == 4) {
        this.copyTitle = "服务分规则"
      } else if (id == 5) {
        this.copyTitle = "行为分规则"
      } else {
        this.copyTitle = "贡献分规则"
      }
      getByType(id).then((res) => {
        this.copyContent = res.data.data.copyContent;
        console.log(res, '--------');
      })
    },
    //订单营收趋势
    instrumentChart() {
      const gaugeData = [
        {
          value: this.score,
          name: this.managerLogoName,
          title: {
            offsetCenter: [0, '-43%'],
            fontSize: 13,
            textStyle: {
              fontSize: 13,
              color: '#000000'
            },
            backgroundColor: '#F1AA21',
            padding: [2, 7, 2, 7],
            borderRadius: 3,
            borderColor: '#808080',
            borderWidth: 1
          },
          detail: {
            valueAnimation: true,
            width: '60%',
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, '-20%'],
            fontSize: 28,
            fontWeight: 'bolder',
            formatter: function (value) {
              return value.toFixed(2);
            },
            color: 'auto'
          }
        },
        {
          name: this.storeUserScoreChangeVo.updateDate + '日更新',
          title: {
            offsetCenter: [0, '-62%'],
            fontSize: 12,
            color: '#333333'
          },
          detail: {
            show: false
          }
        },
        {
          name: this.storeUserScoreChangeVo.upgradeExplain,
          title: {
            offsetCenter: ['0%', '0%'],
            fontSize: 14,
            color: '#FF3A3A',
            fontWeight: 'bold',
          },
          detail: {
            show: false
          }
        }
      ];

      this.instrumentData = {
        series: [
          {
            type: 'gauge',
            center: ['50%', '42%'],
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 110,
            splitNumber: 11,
            itemStyle: {
              color: '#0080FF'
            },
            progress: {
              show: true,
              width: 10,
              roundCap: true
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.25, '#9DC0FA'],
                  [0.5, '#9DC0FA'],
                  [0.75, '#9DC0FA'],
                  [1, '#9DC0FA']
                ]
              }
            },
            axisTick: {
              distance: -25,
              length: 10,
              splitNumber: 10,
              lineStyle: {
                width: 1,
                color: "auto",
              }
            },
            splitLine: {
              distance: -30,
              length: 10,
              lineStyle: {
                width: 1,
                color: "auto"
              }
            },
            // 刻度
            axisLabel: {
              distance: 33,
              color: '#333333',
              fontSize: 13
            },
            anchor: {
              show: false
            },
            title: {},
            detail: {},

            data: gaugeData
          }
        ]
      }
    },
  },
};
</script>

<style scoped lang="scss">
.managerPoints {
  background-color: #f7f9fa;
  padding: 10px;
  overflow: auto;
  

  .header {
    /* display: flex; */
    height: 80px;
    background-color: #fff;
    font-size: 12px;

    .header-top {
      padding: 20px 20px;
    }

    .el-button--primary {
      margin-left: 20px;
    }

    .el-button + .el-button {
      float: right;
    }
  }


  .content {
    width: 100%;
    padding: 15px 0;

    .level {
      text-align: center;

      .currLevel {

        .currLevel_title {
          margin: 0 auto;
          width: 120px;
          height: 20px;
          background: #E6E6E6;
          border-radius: 4px;
          padding: 3px 5px 3px 5px;
        }

        .currLevel_desc {
          margin-top: 10px;
          color: #333333;
        }
      }

      .lastLevel {
        margin-top: 15px;

        .lastLevel_title {
          margin: 0 auto;
          width: 120px;
          height: 20px;
          background: #E6E6E6;
          border-radius: 4px;
          padding: 3px 5px 3px 5px;
        }

        .lastLevel_desc {
          margin-top: 10px;
          color: #333333;
        }
      }
    }
  }

}

.title {
  color: #000;
  padding-right: 20px;
  font-weight: bold;
  font-size: 20px;
}

.scoreChanges {
  /* margin-top: -20px; */
  padding: 30px 0 10px 30px;
  font-size: 16px;
  color: #000;
}

//.instrument {
//  text-align: center;
//  margin: 0 auto;
//}

.eligible {
  background-color: #ff9933;
  color: #000;
  border-radius: 2px;
  padding: 0 10px;
  border: 1px solid #000;
}

.royalty-income {
  margin-top: 45px;
  text-align: center;
  padding-bottom: 15px;
}

.income-item {
  color: red;
}

.item-earnings {
  padding: 0 20px;
  border-radius: 2px;
  background-color: #e6e6e6;
}

.item-deduct {
  font-size: 16px;
  color: #000;
}

::v-deep .my-card {
  text-align: left;
  display: flex;
  flex-direction: column;

  .card-title {
    font-weight: 700;
    font-size: 23px;
    padding: 10px 0 0 20px;
  }

  .title-desc {
    color: #808080;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 0 0 20px;
  }

  .card-footer {
    text-align: center;
    padding-top: 15px;
    color: #0080FF;
    cursor: pointer;
  }

  .num {
    font-size: 25px;
    text-align: center;
    padding: 15px 15px;
    cursor: pointer;

    .angle {
      font-size: 20px;
      color: #808080;
      font-weight: bold;
    }

  }

  .statement {
    height: 75px;
    margin: 0 30px;

    .score_desc {
      color: #808080;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
    }
  }

  .f-item {
    display: flex;
    flex-direction: row;
    background-color: #F2F2F2;
    padding: 10px 0;

    .num-item {
      flex: 1;
      margin-right: 5px;
      text-align: center;
      font-weight: bold;

      .num_item_score {
        font-size: 16px;
      }

      .num_item_score_title {
        font-size: 13px;
        margin-top: 5px;
      }
    }

  }
}

.el-card {
  height: 350px;
  border-radius: 10px;
}

.box-card {
  height: 100%;
}

/deep/ .el-card__body {
  padding: 0;
}

.straight {
  height: 2px;
  background: #EDF4FA;
  margin: 10px 0;
}


.montant {
  border: 0.5px solid #aaaaaa;
  padding: 2px 0;
}

.item3 {
  margin-right: 0;
}

/* 昨日分数 */
.scoreChanges {
  margin: 2px 0px;
}

.yesterday {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 15px 15px;

  .effective_score {
    font-weight: bold;
    font-size: 24px;
  }

  .expired_score {
    font-weight: bold;
    font-size: 24px;
  }

  .yesterday_change_score {
    font-weight: bold;
    font-size: 24px;
    color: #FF3A3A;
  }

  .score_title {
    color: #808080;
    font-weight: 500;
    font-size: 14px;
  }
}

/* .grade{
  font-size: 16px;
} */
span {
  font-size: 14px;
}

.yesterday-item {
  text-align: center;
  padding: 5px 0px;
  font-size: 12px;
}

.past {
  padding-left: 10px;
  font-size: 16px;
}

.item-variation {
  padding-left: 8px;
}

.minusa {
  margin: 3px 0;
  font-size: 24px;
}

.minus {
  margin: 5px 0;
  font-size: 24px;
}

.yesterday-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 30.04px;
  padding-left: 10px;
}

.footer-item {
  display: flex;
  flex-direction: column;
}

.long {
  border: 0.5px solid #E6E6E6;
}

.newEffective {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 14px;
  padding: 5px 0;

  span {
    text-align: center;
    font-weight: 500;
  }

  .score_num {
    font-size: 20px;
    font-weight: bold;
  }

  .score_title {
    color: #333333;
  }
}

.copy-item {
  font-size: 16px;
  font-weight: bolder;
  color: #000;
  font-family: "Times New Roman";
}

/deep/ .el-dialog__title {
  font-size: 25px;
}

/deep/ .el-dialog__header {
  text-align: center;
}

/deep/ .el-dialog__body {
  text-align: center;
  height: 400px;
  text-indent: 30px;
  line-height: 25px;
  vertical-align: middle;
  font-size: 25px;
  font-family: Arial;
  letter-spacing: 1px;
  word-break: break-all;
  overflow: auto;
}

/* 表单 */

</style>
